<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-15 23:57:17
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="isShowCom">
      <el-form :inline="true" label-width="80px" :model="formData">
        <el-row>
          <el-col :span="20">
            <el-form-item label="日期">
              <el-select class="w140" v-model="formData.year" placeholder="请选择年份">
                <el-option
                  :label="year.label"
                  :value="year.value"
                  v-for="year in years"
                  :key="year.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="月份">
              <el-select
                class="w140"
                v-model="formData.month"
                placeholder="请选择月份"
                @change="getDays"
                clearable
              >
                <el-option
                  :label="month.label"
                  :value="month.value"
                  v-for="month in months"
                  :key="month.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="出土方">
              <el-input class="w140" v-model="formData.outName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="进土方">
              <el-input class="w140" v-model="formData.inName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="终审状态">
              <el-select class="w140" v-model="formData.applyStatus" placeholder="请选择">
                <el-option
                  v-for="qtc in status"
                  :label="qtc.label"
                  :value="qtc.value"
                  :key="qtc.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="primary" plain @click="resetFormData">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="table-area">
        <div class="btn-wrap pb10" v-if="!isApprove">
          <el-button type="primary" @click="gotoCreate()">添加补录</el-button>
          <el-button type="primary" plain @click="exportRecordlData">导出</el-button>
        </div>
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
          >
          <el-table-column width="130" label="补录编号" >
            <template slot-scope="scope">
              <span class="theme-color pointer"
              @click="groupTransDetail(scope.row.applyUuid, 'onlyBack')">
                {{ scope.row.no }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="transTime" label="运输日期" />
          <el-table-column prop="outName" label="出土方" />
          <el-table-column prop="customer" width="280" label="客户单位" />
          <el-table-column prop="inName" label="进土方" />
          <el-table-column prop="times" label="运输车次" />
          <el-table-column prop="capacity" label="运输方量" />
          <el-table-column width="100" label="预审状态">
            <template slot-scope="scope">
              <span :class="getPreStateInfo(scope.row.preApplyStatus).color">
                {{getPreStateInfo(scope.row.preApplyStatus).desc}}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="终审状态">
            <template slot-scope="scope">
              <span :class="getStateInfo(scope.row.applyStatus).color">
                {{getStateInfo(scope.row.applyStatus).desc}}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="130" label="操作" v-if="isApprove">
            <template slot-scope="scope">
              <span class="theme-color pointer"
              v-if="scope.row.applyStatus === 1"
              @click="groupTransDetail(scope.row.applyUuid)">
                审批
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createYears, createMonths, createDays, formatDate, globalClose, exportData,
} from '../../utils/index';
import { $gp_groupTransCollection, $gp_groupTransDel, $gp_groupAllQtcList, $gp_proTransListAll } from '../../api/index';

export default {
  name: 'TransportApprove',
  data() {
    return {
      count: 4,
      page: 1,
      planNo: '',
      planName: '',
      tableData: [],
      qtcList: [],
      formData: {
        year: new Date().getFullYear(),
        size: 10,
        page: 1,
      },
      years: [],
      months: [],
      days: [],
      status: [
        { label: '全部', value: null },
        { label: '待审批', value: 1 },
        { label: '已通过', value: 2 },
        { label: '已拒绝', value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isShowCom() {
      const com = ['TransportCheckin', 'TransportApprove'];
      return com.includes(this.$route.name);
    },
    // 是不是审批页面
    isApprove() {
      return this.$route.name === 'TransportApprove';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  mounted() {
    this.years = createYears(5).filter((y) => y.value > 2019);
    this.months = createMonths();
    // this.getQtcList();
    this.groupTransList();
  },
  methods: {
    getPreStateInfo(state){
      const result = state || 1;
      const states = {
        1: { color: 'blue', desc: '待审批' },
        2: { color: 'theme-color', desc: '已通过' },
        3: { color: 'red', desc: '未通过' },
        4:{color:'red',desc:'已撤销'}
      };
      return states[result];
    },
    getStateInfo(state) {
      const result = state || 1;
      const states = {
        1: { color: 'blue', desc: '待审批' },
        2: { color: 'theme-color', desc: '已通过' },
        3: { color: 'red', desc: '已拒绝' },
      };
      return states[result];
    },
    exportRecordlData() {
      const url = '/trans-apply/v3/export';
      const params = this.formData;
      const fileName = '补录记录';
      exportData(url, params, fileName, false);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.groupTransList();
    },
    search() {
      this.groupTransList();
    },
    refreshData() {
      this.groupTransList();
    },
    gotoCreate(info) {
      console.log(info);
      if (info) {
        this.$router.push({ name: 'TransportEdit', params: { info } }); return;
      }
      this.$router.push({ name: 'TransportCreate' });
    },
    groupTransDetail(transUuid, onlyBack) {
      console.log('transUuid', transUuid);
      let path = '/transportCheckin/transportDetail';
      if (this.isApprove) {
        path = '/transportApprove/approve';
        if (onlyBack) {
          this.$router.push({ path, query: { transUuid, onlyBack } });
          return;
        }
      }
      this.$router.push({ path, query: { transUuid } });
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    getQtcList() {
      $gp_groupAllQtcList().then((res) => {
        this.qtcList = res;
      });
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 1,
        year: new Date().getFullYear(),
      };
    },
    formaTime({ transTime }) {
      return formatDate(transTime, 'yyyy-MM-dd');
    },
    groupTransList() {
      console.log(this.formData);
      $gp_groupTransCollection(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    groupTransDel(uuid) {
      this.$confirm('你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          $gp_groupTransDel({ uuid }).then(() => {
            this.$message.success('删除成功');
            this.groupTransList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
