import { render, staticRenderFns } from "./transportRecord.vue?vue&type=template&id=391558df&scoped=true&"
import script from "./transportRecord.vue?vue&type=script&lang=js&"
export * from "./transportRecord.vue?vue&type=script&lang=js&"
import style0 from "./transportRecord.vue?vue&type=style&index=0&id=391558df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "391558df",
  null
  
)

export default component.exports