<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-28 20:33:25
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'TransportCount'">
      <div class="table-area">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="按天统计" name="pro-qtc">
            <div class="flex">
              <div class="left-table">
                <el-form :inline="true" label-width="90px" :model="formData">
                  <template>
                    <el-form-item label="选择日期">
                      <el-date-picker
                        class="w160"
                        v-model="formData.date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="开始时间"
                      />
                    </el-form-item>
                    <el-form-item label="出土方">
                      <el-input v-model="formData.outName" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="进土方">
                      <el-input v-model="formData.inName" placeholder="请输入" />
                    </el-form-item>
                  </template>
                  <el-form-item class="center">
                    <el-button type="primary" @click="search">查询</el-button>
                    <el-button type="primary" plain @click="resetData">重置</el-button>
                    <el-button type="info" plain @click="handleExport('day')">导出</el-button>
                    <el-button type="primary" plain @click="handlePrint('1', pro.properties1)"
                      >打印</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                  :data="tableData"
                  border
                  show-summary
                  :summary-method="getTotalData"
                  :row-style="tableRowStyle"
                  :header-cell-style="$rowClass"
                  style="width: 100%"
                  @row-click="rowClick"
                >
                  <el-table-column prop="outName" label="出土方" />
                  <el-table-column prop="customer" width="280" label="客户单位" />
                  <el-table-column prop="inName" label="进土方" />
                  <el-table-column prop="times" label="运输车次" />
                  <el-table-column prop="capacity" label="运输方量" />
                </el-table>
                <div class="pagination-block">
                  <el-pagination
                    background
                    :current-page.sync="formData.page"
                    @current-change="handlerCurPage"
                    :total="count"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="right-table">
                <el-form :inline="true" label-width="90px" :model="carSearch">
                  <template>
                    <el-form-item label="车牌号码">
                      <el-input v-model="carSearch.carNo" placeholder="请输入" />
                    </el-form-item>
                  </template>
                  <el-form-item class="center">
                    <el-button type="primary" @click="searchCarNo">查询</el-button>
                    <el-button type="primary" plain @click="resetData">重置</el-button>
                    <el-button type="info" plain @click="handleExport('daySub')">导出</el-button>
                    <el-button type="primary" plain @click="handlePrintCar(pro.properties2)"
                      >打印</el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                  :data="carNoData"
                  border
                  show-summary
                  :summary-method="getCarTotalData"
                  :header-cell-style="$rowClass"
                  style="width: 100%"
                >
                  <el-table-column prop="carNo" label="车牌号码" />
                  <el-table-column prop="times" label="运输车次" />
                  <el-table-column prop="capacity" label="运输方量" />
                </el-table>
                <div class="pagination-block">
                  <el-pagination
                    background
                    :current-page.sync="carSearch.page"
                    @current-change="handlerCarPage"
                    :total="carCount"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="按月统计" name="first">
            <el-form :inline="true" label-width="90px" :model="formData">
              <template>
                <el-form-item label="选择日期">
                  <el-date-picker
                    class="w160"
                    v-model="formData.startDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @change="changDate"
                    placeholder="开始时间"
                  />
                </el-form-item>
                <el-form-item label="结束日期">
                  <el-date-picker
                    class="w160"
                    v-model="formData.endDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @change="checkedDate"
                    placeholder="结束时间"
                  />
                </el-form-item>
                <el-form-item label="出土方">
                  <el-input v-model="formData.outName" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="进土方">
                  <el-input v-model="formData.inName" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="客户单位">
                  <el-input v-model="formData.customer" placeholder="请输入" />
                </el-form-item>
              </template>
              <el-form-item class="center">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="primary" plain @click="resetData">重置</el-button>
                <el-button type="info" plain @click="handleExport('month')">导出</el-button>
                <el-button type="primary" plain @click="handlePrint('2', pro.properties3)"
                  >打印</el-button
                >
              </el-form-item>
            </el-form>
            <el-table
              :data="tableData"
              border
              show-summary
              :summary-method="getTotalData"
              :header-cell-style="$rowClass"
              style="width: 100%"
              @header-click="headerClick"
            >
              <el-table-column prop="outName" label="出土方" />
              <el-table-column prop="customer" width="280" label="客户单位" />
              <el-table-column prop="inName" label="进土方" />
              <el-table-column prop="times" label="运输车次" />
              <el-table-column prop="capacity" label="运输方量" />
              <el-table-column prop="timePrice" :formatter="formatTimePrice" label="300元/车">
                <template slot="header">
                  <TableCustomHeader :headerInfo="timesHeader"></TableCustomHeader>
                </template>
              </el-table-column>
              <el-table-column :formatter="formatCapacityPrice" label="300元/车">
                <template slot="header">
                  <TableCustomHeader :headerInfo="capacityHeader"></TableCustomHeader>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination-block">
              <el-pagination
                background
                :current-page.sync="formData.page"
                @current-change="handlerCurPage"
                :total="count"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="按项目统计" name="second">
            <el-form :inline="true" label-width="90px" :model="formData">
              <template>
                <el-form-item label="项目名称">
                  <el-input v-model="formData.projectName" placeholder="请输入项目名称" />
                </el-form-item>
              </template>
              <el-form-item class="center">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="primary" plain @click="resetData">重置</el-button>
                <el-button type="info" plain @click="handleExport('project')">导出</el-button>
                <el-button type="primary" plain @click="handlePrint('3', pro.properties4)"
                  >打印</el-button
                >
              </el-form-item>
            </el-form>
            <el-table :data="tableData" border :header-cell-style="$rowClass" style="width: 100%">
              <el-table-column prop="projectName" label="项目名称" />
              <el-table-column prop="customer" width="280" label="客户单位" />
              <el-table-column prop="outTimes" label="出土车次" />
              <el-table-column prop="outCapacity" label="出土方量" />
              <el-table-column prop="inTimes" label="进土车次" />
              <el-table-column prop="inCapacity" label="进车方量" />
            </el-table>
            <div class="pagination-block">
              <el-pagination
                background
                :current-page.sync="formData.page"
                @current-change="handlerCurPage"
                :total="count"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="修改单价" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="">
          <el-input
            type="number"
            v-model="price"
            placeholder="请输入单价"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPrice">确 定</el-button>
      </div>
    </el-dialog>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import printJS from "print-js";
import { getDaysBetween, formatDate, deepClone } from "../../utils/index";
import TableCustomHeader from "../../components/TableCustomHeader.vue";
import { exportData } from "../../utils/index";
import {
  $gp_transStatisticsDay,
  $gp_transStatisticsDayTotal,
  $gp_transStatisticsDaySub,
  $gp_transStatisticsMonth,
  $gp_transStatisticsMonthTotal,
  $gp_transStatisticsPro,
  $gp_transStatisticsSubTotal,
  $gp_transManualTotal
} from "../../api/index";
export default {
  name: "TransportCount",
  components: { TableCustomHeader },
  data() {
    return {
      carCount: 0,
      count: 0,
      carNoData: [],
      carSearch: {
        size: 10,
        page: 1
      },
      tableData: [],
      formData: {
        size: 10,
        page: 1
      },
      activeName: "pro-qtc",
      price: 0, // 计算-单价
      totalMoney: 0, // 计算-统计金额
      dialogFormVisible: false,
      timesHeader: {
        title: "330元/车",
        value: 330
      },
      capacityHeader: {
        title: "--元/方",
        value: 0
      },
      curFiled: "",
      curUuid: "",
      pro: {
        properties1: [
          { field: "outName", displayName: "出土方" },
          { field: "customer", displayName: "客户单位" },
          { field: "inName", displayName: "进土方" },
          { field: "times", displayName: "运输车次" },
          { field: "capacity", displayName: "运输方量" }
        ],
        properties2: [
          { field: "carNo", displayName: "车牌号码" },
          { field: "times", displayName: "运输车次" },
          { field: "capacity", displayName: "运输方量" }
        ],
        properties3: [
          { field: "outName", displayName: "出土方" },
          { field: "customer", displayName: "客户单位" },
          { field: "inName", displayName: "进土方" },
          { field: "times", displayName: "运输车次" },
          { field: "capacity", displayName: "运输方量" },
          { field: "timePrice", displayName: "300元/车" },
          { field: "capacityPrice", displayName: "300元/车" }
        ],
        properties4: [
          { field: "projectName", displayName: "项目名称" },
          { field: "customer", displayName: "客户单位" },
          { field: "outTimes", displayName: "进土方" },
          { field: "outCapacity", displayName: "运输车次" },
          { field: "inTimes", displayName: "运输方量" },
          { field: "inCapacity", displayName: "进车方量" }
        ]
      },
      gridStyle: "text-align: left;border: 1px solid lightgray;font-size: 12px; line-height: 24px;",
      gridHeaderStyle: "font-weight: 500; border: 1px solid lightgray; line-height: 32px;",
      headerStyle: "font-weight: 300;font-size:24px;text-align:center"
    };
  },
  computed: {
    ...mapGetters(["updateTime", "userInfo"]),
    isGroupUser() {
      return this.userInfo.role === "GROUP_MANAGER";
    }
  },
  created() {
    this.getParams();
    this.getTableData();
  },
  methods: {
    handlePrintCar(p) {
      let tablesCarRows = [];
      let headerTitle = "车牌-弃土运输统计";
      if (this.carNoData.length < 1) {
        this.$message.info("打印表格不能为空！");
        return;
      }
      tablesCarRows = this.carNoData.map(res => {
        return res;
      });
      const totalsCar = this.getCarTotalData();
      const totalCarObj = {
        carNo: totalsCar[0],
        times: totalsCar[1],
        capacity: totalsCar[2]
      };
      tablesCarRows.push(totalCarObj);
      const printObj = {
        type: "json",
        documentTitle:"",
        printable: tablesCarRows,
        // 打印json时传入的表头名称(必传)，要和json中的键值对的键保持一致
        properties: p,
        // 打印JSON时，表格的自定义样式
        gridStyle: this.gridStyle,
        gridHeaderStyle: this.gridHeaderStyle,
        header: headerTitle,
        headerStyle: this.headerStyle
      };
      printJS(printObj);
    },
    handlePrint(type, p) {
      if (this.tableData.length < 1) {
        this.$message.info("打印表格不能为空！");
        return;
      }
      let tablesRows = [];
      let headerTitle = "";
      switch (type) {
        case "1":
          tablesRows = this.tableData.map(res => {
            return res;
          });
          headerTitle = this.formData.date + "弃土运输统计";
          const totals = this.getTotalData();
          const totalObj = {
            outName: totals[0],
            customer: totals[1],
            inName: totals[2],
            times: totals[3],
            capacity: totals[4]
          };
          tablesRows.push(totalObj);
          break;
        case "2":
        headerTitle = this.formData.startDate + "至" + this.formData.endDate + "弃土运输统计";
          let tablesRowsArrs = this.tableData.map(res => {
            res.timePrice = this.formatTimePrice({ times: res.times });
            res.capacityPrice = this.formatCapacityPrice({ capacity: res.capacity });
            return res;
          });

          const totals2 = this.getTotalData();
          const totalObj2 = {
            outName: totals2[0],
            customer: totals2[1],
            inName: totals2[2],
            times: totals2[3],
            capacity: totals2[4],
            timePrice: totals2[5],
            capacityPrice: totals2[6]
          };
          tablesRowsArrs.push(totalObj2);
          tablesRows = tablesRowsArrs;
          break;
        case "3":
          tablesRows = this.tableData;
          headerTitle = "项目-出土统计表";
          break;
        default:
      }
      const obj = {
        type: "json",
        documentTitle: "",
        printable: tablesRows,
        // 打印json时传入的表头名称(必传)，要和json中的键值对的键保持一致
        properties: p,
        // 打印JSON时，表格的自定义样式
        gridStyle: this.gridStyle,
        gridHeaderStyle: this.gridHeaderStyle,
        header: headerTitle,
        headerStyle: this.headerStyle

      };
      printJS(obj);
    },
    tableRowStyle({ row }) {
      console.log(row);
      const uuid = `${row.inUuid}-${row.outUuid}-${row.capacity}`;
      if (uuid === this.curUuid) {
        return { background: "#1abc9c29" };
      }
      return "";
    },
    getParams() {
      const page = 1;
      const size = 10;
      const startDate = this.getDefaultData();
      const date = this.getCurDate();
      if (this.activeName === "first") {
        const params = {
          startDate,
          endDate: date,
          page,
          size
        };
        this.formData = params;
      } else if (this.activeName === "pro-qtc") {
        this.formData = { date, page, size };
      } else {
        this.formData = { page, size };
      }
    },
    headerClick(val) {
      console.log("val", val);
      this.dialogFormVisible = !this.dialogFormVisible;
      this.curFiled = val.property;
      if (this.curFiled === "timePrice") {
        this.price = this.timesHeader.value;
      } else {
        this.price = this.capacityHeader.value;
      }
    },
    editPrice() {
      if (this.curFiled === "timePrice") {
        this.timesHeader.title = `${this.price}元/车`;
        this.timesHeader.value = this.price;
        console.log(this.timesHeader);
      } else {
        const price = +this.price === 0 ? "--" : this.price;
        this.capacityHeader.title = `${price}元/方`;
        this.capacityHeader.value = this.price;
      }
      this.dialogFormVisible = false;
    },
    formatTimePrice({ times }) {
      return +this.timesHeader.value * +times;
    },
    formatCapacityPrice({ capacity }) {
      const total = +this.capacityHeader.value * +capacity;
      return total === 0 ? "--" : total;
    },
    // 日期切换
    changDate(value, type) {
      console.log(value, type);
      this.checkedDate();
    },
    // 检查日期选择是否正确
    checkedDate() {
      if (!this.formData.startDate) {
        this.$message.error("请选择开始时间");
        return;
      }
      console.log(this.formData.startDate, this.formData.endDate);
      if (this.formData.endDate) {
        const days = getDaysBetween(this.formData.startDate, this.formData.endDate);
        console.log(days);
        if (days > 31) {
          this.$message.error("每次最多统计连续31天的数据");
          this.formData.endDate = null;
        }
      }
    },
    getCurDate() {
      return formatDate(new Date(), "yyyy-MM-dd");
    },

    // 获取上月21日的日期
    getDefaultData() {
      const data = new Date();
      let y = data.getFullYear();
      let m = new Date().getMonth();
      if (m === 0) {
        y -= 1;
        m = 12;
      }
      m = m < 10 ? `0${m}` : m;
      return `${y}-${m}-21`;
    },
    getActivateName() {
      this.activeName = "first";
      if (this.userInfo.role === "GROUP_MANAGER") {
        this.activeName = "pro-qtc";
      }
    },

    handleClick(tab) {
      this.activeName = tab.name;
      this.getParams();
      this.getTableData();
    },
    searchCarNo() {
      this.getCarNoData();
    },
    handlerCurPage(val) {
      this.formData.page = val;
      this.getTableData();
    },
    handlerCarPage(val) {
      this.carSearch.page = val;
      this.getCarNoData();
    },
    search() {
      this.getTableData();
    },
    resetData() {
      this.getParams();
    },
    rowClick(val) {
      const { inUuid, outUuid, capacity } = val;
      this.uuids = { inUuid, outUuid };
      this.curUuid = `${inUuid}-${outUuid}-${capacity}`;
      this.getCarNoData();
    },
    async getCarNoData() {
      if (!this.uuids) {
        this.$message.info("请先在左表选定一条统计记录");
        return;
      }
      const params = {
        ...this.uuids,
        ...this.formData,
        ...this.carSearch
      };
      const resp = await $gp_transStatisticsSubTotal(params);
      const tParams = deepClone(params);
      delete tParams.page;
      delete tParams.size;
      const resTotal = await $gp_transManualTotal(tParams);
      resTotal.carNo = "补录";
      console.log("resTotal", resTotal);

      const carTotal = ["总计", resp.times, resp.capacity];
      $gp_transStatisticsDaySub(params).then(res => {
        this.carNoData = [resTotal, ...res.list];
        this.carCount = res.totalCount;
        this.tableCarDataCount = carTotal;
      });
    },
    async getTableData() {
      let func;
      let totalFun;
      let count = [];
      if (this.activeName === "pro-qtc") {
        func = $gp_transStatisticsDay;
        totalFun = $gp_transStatisticsDayTotal;
      }
      if (this.activeName === "first") {
        func = $gp_transStatisticsMonth;
        totalFun = $gp_transStatisticsMonthTotal;
      }
      if (this.activeName === "second") {
        func = $gp_transStatisticsPro;
      }
      if (totalFun) {
        const resp = await totalFun(this.formData);
        if (this.activeName === "pro-qtc") {
          count = ["总计", "-", "-", resp.times, resp.capacity, "-"];
        } else if (this.activeName === "first") {
          count = this.getMonthTotal(resp.times, resp.capacity);
        } else {
          count = ["总计", "-", "-", resp.times, resp.capacity];
        }
      }
      if (func) {
        func(this.formData).then(res => {
          this.tableData = res.list;
          this.count = res.totalCount;
          this.tableDataCount = count;
        });
      }
    },
    getMonthTotal(times, capacity) {
      const timesPrice = +this.timesHeader.value * times;
      let capacityPrice = +this.capacityHeader.value * capacity;
      capacityPrice = capacityPrice === 0 ? "--" : capacityPrice;
      return ["总计", "-", "-", times, capacity, timesPrice, capacityPrice];
    },
    // 统计数据
    getTotalData() {
      const count = ["总计", 0, 0];
      const sums = this.tableDataCount;
      return sums || count;
    },
    getCarTotalData() {
      const count = ["总计", 0, 0];
      const sums = this.tableCarDataCount;
      return sums || count;
    },

    // 导出
    handleExport(type) {
      let url;
      let params;
      let fileName;
      switch (type) {
        case "day":
          url = "/trans-statistics/v3/export/day";
          params = this.formData;
          fileName = "按天运输统计明细";
          exportData(url, params, fileName, false);
          break;
        case "daySub":
          if (!this.uuids) {
            this.$message.info("请先在左表选定一条统计记录");
            return;
          }
          const params_sub = {
            ...this.uuids,
            ...this.formData,
            ...this.carSearch
          };
          url = "/trans-statistics/v3/export/day/sub";
          params = params_sub;
          fileName = "按车牌运输统计明细";
          exportData(url, params, fileName, false);
          break;
        case "month":
          url = "/trans-statistics/v3/export/month";
          params = this.formData;
          fileName = "按月运输统计明细";
          exportData(url, params, fileName, false);
          break;
        case "project":
          url = "/trans-statistics/v3/export/project";
          params = this.formData;
          fileName = "按项目运输统计明细";
          exportData(url, params, fileName, false);
          break;
        default:
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@page{
  margin:0
}

.el-table__row .success-row {
  background: #f0f9eb;
}
.left-table {
  position: relative;
  margin-right: 40px;
}
.left-table::after {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  right: -20px;
  top: 0;
  background-color: #eee;
}
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
    span {
      padding-right: 10px;
    }
  }
}
</style>
