<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-06 20:54:39
-->
<template>
  <div class="main">
    <div class="print-com module-wrap">
      <el-form class="flex fsbc"
        :label-position="labelPosition"
        label-width="80px"
        :model="formData"
      >
        <div class="input-area">
          <el-form-item label="日期">
            <el-date-picker
              class="w160"
              v-model="formData.date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="运输状态">
            <el-select  class="w160" v-model="formData.status" placeholder="请选择日期">
              <el-option :label="item.label" v-for="item in transportState"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="出土方">
            <el-input v-model="formData.outName" placeholder="请输入" class="w160"/>
          </el-form-item>
          <el-form-item label="进土方">
            <el-input v-model="formData.inName" placeholder="请输入" class="w160"/>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="formData.carNo" placeholder="请输入" class="w160"/>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetData">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table-data">
        <p class="pb10">
          <el-button type="primary" plain @click="exportRecordlData">导出</el-button>
        </p>
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="no" width="160" label="运输编号" />
          <el-table-column prop="carNo" width="130" label="车牌号" />
          <el-table-column prop="capacity" width="80" label="弃土方量" />
          <el-table-column prop="outName" label="出土方" />
          <el-table-column prop="customer" label="客户单位" />
          <el-table-column prop="startTime" width="200" label="出发时间" />
          <el-table-column prop="inName" width="100" label="进土方" />
          <el-table-column prop="endTime" width="200" label="到达时间" />
          <el-table-column :formatter="formatDuration" width="80" label="运输时长" />
          <el-table-column :formatter="formatComplete" width="80" label="运输状态" />
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  formatDate,
  globalClose,
  exportData,
} from '../../utils/index';
import {
  $gp_proTransList,
} from '../../api/index';

export default {
  name: 'TransportRecord',
  data() {
    return {
      labelPosition: 'right',
      count: 5,
      formData: {
        page: 1,
        size: 10,
        status: null,
        date: '',
      },
      tableData: [],
      transportState: [
        { label: '全部', val: null },
        { label: '运输中', val: 1 },
        { label: '已完成', val: 2 },
      ],
    };
  },
  created() {
    this.formData.date = formatDate(new Date(), 'yyyy-MM-dd');
    this.groupTransList();
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isGroupUser() {
      return this.userInfo.role === 'GROUP_MANAGER';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  methods: {
    exportRecordlData() {
      const url = '/trans/v3/export';
      const params = this.formData;
      const fileName = '运输记录明细';
      exportData(url, params, fileName, false);
    },
    search() {
      this.formData.page = 1;
      this.groupTransList();
    },
    handlerCurPage(val) {
      this.formData.page = val;
      this.groupTransList();
    },
    resetData() {
      this.formData = {
        date: formatDate(new Date(), 'yyyy-MM-dd'),
        page: 1,
        size: 10,
        status: null,
      };
    },
    formatDuration({ duration }) {
      if (duration) {
        return `${duration}分`;
      }
      return '--';
    },
    handleSizeChange(val) {
      console.log(val);
    },
    formatComplete({ status }) {
      console.log(status);
      const state = {
        1: '运输中',
        2: '已完成',
        3: '已取消',
      };
      return state[status] || '--';
    },
    groupTransList() {
      $gp_proTransList(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 48%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
.input-area{
  display: flex;
  flex-wrap: wrap;
}

.input-area{
  flex: 1
}
.subitem-area{
  display: flex;
  flex-direction: column;
  width: 100px;
  p{
    box-sizing: border-box;
    text-align: bottom;
    &:last-child{
      margin-top: 24px;
      margin-bottom: 20px
    }
  }
}
</style>
